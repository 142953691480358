import axios from 'axios';

const app = axios.create({
    withCredentials: true
})

app.interceptors.response.use(
  response => (response), 
  error => {
  if(error!.response!)
  {
    if (error!.response!.status === 401) {
        localStorage.clear();
        if(window.location.href.includes('quick-add'))
        {
          return;
        }else{
          window.location.href = "/login";
        }
    }
  }
  
  return Promise.reject(error);
  }
)

export default app;