import {
  Button,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Option,
  Stack,
  Card,
  CircularProgress,
  Box,
  Divider,
  Avatar,
  AspectRatio,
  Link,
  IconButton,
  LinearProgress,
  Tooltip,
} from "@mui/joy";
import { Button as MButoon } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
// const BackgroundRongPhuong = require("../../assets/images/RongPhuong.svg") as string;
import BackgroundRongPhuong from "../../assets/images/RongPhuong.svg";
import configs from "../../config";
import { isEmpty, validateEmail, validatePhone } from "../../common/expression";
import { Package } from "../packages/Package.type";
import { useEffect, useRef } from "react";
import axios, { AxiosError } from "axios";
import CommonPopupRender from "../../common/popuprender";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import BackgroundIMG from "../../assets/images/bggiapha.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import Logo from "../../assets/images/logo-large.webp";
const initialValidate = { invalid: false, errors: "" };
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";
type ServerError = { message: string };
import "./QuickAddCustomer.css";
import { Backdrop, ButtonGroup } from "@mui/material";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

declare global {
  interface Window {
    captchaOnLoad: () => void;
    grecaptcha: any;
  }
}

export default function QuickAddCustomer() {
  registerLocale("vi", vi);
  let navigate = useNavigate();
  const nameForm = useRef(null);
  const [state, setState] = React.useState({
    full_name: "",
    family_name: "",
    password: configs.customer_pwd,
    email: "",
    birth_day: undefined,
    gender: 0,
    phone: "",
    package_id: "",
  });

  const [initPopup, setInitPopup] = React.useState({
    openFlag: false,
    type: "",
    content: "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const [validateName, setvalidateName] = React.useState(initialValidate);
  const [open, setOpen] = React.useState(false);
  const [validateFamilyname, setvalidateFamilyname] =
    React.useState(initialValidate);
  const [validatePwd, setvalidatePwd] = React.useState(initialValidate);
  const [validateEmails, setvalidateEmail] = React.useState(initialValidate);
  const [validatePhones, setvalidatePhone] = React.useState(initialValidate);
  const [list_package, setListPack] = React.useState<Package[]>([]);
  const [loading, SetLoading] = React.useState(false);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up("lg"));

  const packageList = async () => {
    axios
      .post(configs.api + "package/get_trial")
      .then((response) => {
        setListPack(response.data);
        setState({ ...state, ["package_id"]: response.data[0]._id });
      })
      .catch((err) => {
        setListPack([]);
        setState({ ...state, ["package_id"]: "" });
      });
  };
  const removeRecaptcha = () => {
    const script = document.getElementById("recaptcha-key");
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
  };

  useEffect(() => {
    packageList();
    document.title = "Đăng ký Dịch vụ Gia phả số";
    const loadScriptByURL = (id: any, url: any, callback: () => void) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);  
      }

      if (isScriptExist && callback) callback();
    };
    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${configs.reCaptchaKey}`,
      () => {
        // console.log('Script recaptcha loaded!');
      }
    );
  }, []);

  const openTele = () => window.open("tel:0979338811", "_self");

  const openMail = () =>
    window.open(
      "mailto:hotro@giaphadaiviet.com?subject=" +
        encodeURIComponent("[Gia phả số] Đăng ký dịch vụ"),
      "_self"
    );

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
  };
  const handleDateChange = (date: any) => {
    setState({ ...state, birth_day: date });
  };
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    SetLoading(true);
    event.preventDefault();
    let cycleday = 0;

    if (list_package.find((x) => x.price == 0)) {
      cycleday = list_package.find((x) => x.price == 0)!.cycle_day || 0;
    }

    if (
      state.full_name == "" ||
      state.family_name == "" ||
      state.password == "" ||
      state.email == "" ||
      state.phone == ""
    ) {
      SetLoading(false);
      return;
    }

    if (
      validateName.invalid ||
      validateEmails.invalid ||
      validateFamilyname.invalid ||
      validatePwd.invalid ||
      validatePhones.invalid
    ) {
      SetLoading(false);
      return;
    }
    const package_name = list_package.find(
      (x) => x._id == state.package_id
    )!.package_name;
    await window.grecaptcha.ready(async () => {
      await window.grecaptcha
        .execute(configs.reCaptchaKey, { action: "submit" })
        .then(async (token: any) => {
          await axios
            .post(configs.customer + "trialcustomer", {
              family_name: state.family_name,
              pwd: state.password,
              full_name: state.full_name,
              email: state.email.trim(),
              phone: state.phone.trim(),
              token,
              birth_day: state.birth_day,
              gender: state.gender,
              package_id: list_package.find((x) => x.price == 0)!._id,
              selected_id: state.package_id,
              package_name,
              cycleday,
            })
            .then(async (data: any) => {
              console.log(data);
              SetLoading(false);
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "success",
                content: "Cảm ơn quý khách đã đăng ký dịch vụ Gia Phả Số",
                onPass: () => {
                  // window.location.reload();
                  SetLoading(false);
                  window.location.assign(configs.trial_domain[0]);
                },
                onCancel: () => {
                  SetLoading(false);
                },
              });
              removeRecaptcha();
            })
            .catch((err) => {
              console.log(err);
              if (axios.isAxiosError(err)) {
                const serverError = err as AxiosError<ServerError>;
                if (serverError) {
                  if(serverError!.response!.status == 400)
                  {
                    setInitPopup({
                      ...initPopup,
                      openFlag: true,
                      type: "fail",
                      content: serverError!.response!.data.message,
                      onPass: () => {
                        SetLoading(false);
                      },
                      onCancel: () => {
                        SetLoading(false);
                      },
                    });
                  }else{
                    setInitPopup({
                      ...initPopup,
                      openFlag: true,
                      type: "fail",
                      content: "Có lỗi trong quá trình xử lý!",
                      onPass: () => {
                        SetLoading(false);
                      },
                      onCancel: () => {
                        SetLoading(false);
                      },
                    });
                  }
                }
              }
            });
        })
        .catch((err: any) => {
          SetLoading(false);
          if (axios.isAxiosError(err)) {
            const serverError = err as AxiosError<ServerError>;
            if (serverError && serverError.response) {
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "fail",
                content: `Lỗi xác thực captcha!`,
                onPass: () => {
                  SetLoading(false);
                },
                onCancel: () => {
                  SetLoading(false);
                },
              });
            }
          }
        });
    });
  };

  const onBlurNameHandler = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    } else {
      setvalidateName({ invalid: false, errors: "" });
    }
  };

  const onBlurFamilyName = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateFamilyname({
        invalid: true,
        errors: "Tên dòng họ không được để trống",
      });
    } else {
      setvalidateFamilyname({ invalid: false, errors: "" });
    }
  };

  const onBlurEmail = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email không được để trống",
      });
    } else if (!validateEmail(e.target.value.trim())) {
      setvalidateEmail({
        invalid: true,
        errors: "Email sai định dạng",
      });
    } else setvalidateEmail({ invalid: false, errors: "" });
  };

  const onBlurPhone = (e: any) => {
    if (isEmpty(e.target.value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại không được để trống",
      });
    } else if (!validatePhone(e.target.value.trim())) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại sai định dạng",
      });
    } else setvalidatePhone({ invalid: false, errors: "" });
  };

  const onValidFamilyName = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateFamilyname({
        invalid: true,
        errors: "Tên dòng họ không được để trống",
      });
    }
  };

  const onInputFamilyName = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateFamilyname({ invalid: false, errors: "" });
  };

  const onValidFullName = (e: any) => {
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateName({
        invalid: true,
        errors: "Họ và tên không được để trống",
      });
    }
    (e.target as HTMLInputElement).setCustomValidity(" ");
  };

  const onInputFullName = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateName({ invalid: false, errors: "" });
  };

  const onValidEmail = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidateEmail({
        invalid: true,
        errors: "Email không được để trống",
      });
    }
  };
  const onInputEmail = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidateEmail({ invalid: false, errors: "" });
  };

  const onValidPhone = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity(" ");
    if (isEmpty((e.target as HTMLInputElement).value)) {
      setvalidatePhone({
        invalid: true,
        errors: "Số điện thoại không được để trống",
      });
    }
  };

  const onInputPhone = (e: any) => {
    (e.target as HTMLInputElement).setCustomValidity("");
    setvalidatePhone({ invalid: false, errors: "" });
  };

  const onChangePackage = (e: any, newValue: any) => {
    setState({
      ...state,
      package_id: newValue || list_package[0]._id,
    });
  };
  const openLogin = ()=>{
    window.location.assign(configs.trial_domain[0]);
  }
  const copyClipboard = (value: string) => {
    navigator.clipboard.writeText(value ? value : "");
    handleClick();
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} color="success" sx={{ width: "100%" }}>
          Đã sao chép
        </Alert>
      </Snackbar>
      <div
        className="MainBody"
        style={{
          backgroundColor: "#fff8a9",
          backgroundPosition: "center",
          backgroundImage: `url(${BackgroundRongPhuong})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
          width: "100%",
          alignContent: "center",
          color: "#003168",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          className="mainCard"
          sx={{
            border: "0.1px solid",
            width: laptopUI ? "40%" : "30%",
            padding:"10px 5px",
            backgroundImage: `url(${BackgroundIMG})`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "10px",
              padding:"5px",
              flexDirection:{
                // xs:"column",
                md:"row"
              }
            }}
          >
            <a
              href="https://giaphaso.com"
              title="Gia phả số Đại Việt trực tuyến"
              style={{ margin: "auto 0", width:"50%" }}
            >
              <Box
                sx={{
                  backgroundPosition: "center",
                  backgroundImage: `url(${Logo})`,
                  width: { xs: "120px", md: "150px" },
                  height: { xs: "80px", md: "100px" },
                  objectFit: "cover",
                  margin:"auto 0",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />
            </a>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                justifyContent: "center",
              }}
            >
              <ButtonGroup
                size="small"
                variant="outlined"
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <MButoon
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px 0px 0px 8px",
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "200px",
                    gap: 0.5,
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "rgba(25, 118, 210)",
                      textAlign: "center",
                      border: "1px solid rgba(25, 118, 210, 0.5)",
                    },
                  }}
                  onClick={openTele}
                >
                  <PhoneIcon fontSize="small" />
                  <div>0979.33.88.11</div>
                </MButoon>
                <Tooltip title="Sao chép">
                  <MButoon
                  style={{borderRadius:"0px 8px 8px 0px"}}
                    onClick={() => {
                      copyClipboard("0979338811");
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </MButoon>
                </Tooltip>
              </ButtonGroup>
              <ButtonGroup size="small" sx={{ margin: "auto" }}>
                <MButoon
                  startIcon={
                    // <img
                    //   src="https://giapha.akb.com.vn/images/email.png"
                    //   width="24px"
                    // />
                    <EmailIcon fontSize="small" />
                  }
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px 0px 0px 8px",
                    width: "200px",
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "rgba(25, 118, 210)",
                      border: "1px solid rgba(25, 118, 210, 0.5)",
                    },
                  }}
                  onClick={openMail}
                >
                  hotro@giaphadaiviet.com
                </MButoon>
                <Tooltip title="Sao chép">
                  <MButoon
                    style={{borderRadius:"0px 8px 8px 0px"}}
                    onClick={() => {
                      copyClipboard("hotro@giaphadaiviet.com");
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </MButoon>
                </Tooltip>
              </ButtonGroup>
              <Link
                variant="outlined"
                startDecorator={<LanguageIcon fontSize="small" />}
                underline="hover"
                href="https://giaphaso.com"
                fontSize="sm"
                sx={{
                  margin: "auto",
                  width: "95%",
                  maxWidth:"230px",
                  border: "1px solid rgba(25, 118, 210, 0.5)",
                  height: "25px",
                  borderRadius: "8px",
                  ":hover": {
                    bgcolor: "rgba(25, 118, 210, 0.04)",
                    border: "1px solid rgba(25, 118, 210)",
                  },
                }}
              >
                https://giaphaso.com
              </Link>
            </Box>
          </Box>

          <Typography
            level={laptopUI ? "h3" : "h4"}
            component={laptopUI ? "h4" : "h1"}
            sx={{
              alignSelf: "center",
              textAlign: "center",
              color: "#003168",
              fontWeight:"bold",
              padding:"0px 11px"
            }}
          >
            ĐĂNG KÝ DỊCH VỤ GIA PHẢ SỐ
          </Typography>

          <form ref={nameForm} onSubmit={onSubmitHandler} style={{padding:"11px"}}>
            <Stack spacing={2}>
              <FormControl required>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>
                  Họ và tên
                </FormLabel>
                <Input
                  autoFocus
                  name="full_name"
                  type="text"
                  slotProps={{
                    input: {
                      maxLength: 250,
                    },
                  }}
                  error={validateName.invalid}
                  sx={{
                    border: "1px solid",
                    backgroundColor: "#DDF1FF",
                  }}
                  color="primary"
                  onChange={handleChange}
                  value={state.full_name}
                  onBlur={onBlurNameHandler}
                  onInvalid={onValidFullName}
                  onInput={onInputFullName}
                />

                {validateName.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateName.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl required>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>
                  Tên dòng họ
                </FormLabel>
                <Input
                  name="family_name"
                  type="text"
                  slotProps={{
                    input: {
                      maxLength: 250,
                    },
                  }}
                  sx={{
                    border: "1px solid",
                    backgroundColor: "#DDF1FF",
                  }}
                  color="primary"
                  onChange={handleChange}
                  error={validateFamilyname.invalid}
                  value={state.family_name}
                  onBlur={onBlurFamilyName}
                  onInvalid={onValidFamilyName}
                  onInput={onInputFamilyName}
                />
                {validateFamilyname.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateFamilyname.errors}{" "}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl required>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>Email</FormLabel>
                <Input
                  name="email"
                  type="text"
                  slotProps={{
                    input: {
                      maxLength: 50,
                    },
                  }}
                  sx={{
                    border: "1px solid",
                    backgroundColor: "#DDF1FF",
                  }}
                  color="primary"
                  onChange={handleChange}
                  error={validateEmails.invalid}
                  onInvalid={onValidEmail}
                  onInput={onInputEmail}
                  value={state.email}
                  onBlur={onBlurEmail}
                />
                {validateEmails.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validateEmails.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl required>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>
                  Số điện thoại
                </FormLabel>
                <Input
                  name="phone"
                  type="text"
                  sx={{
                    border: "1px solid",
                    backgroundColor: "#DDF1FF",
                  }}
                  color="primary"
                  onChange={handleChange}
                  onInvalid={onValidPhone}
                  onInput={onInputPhone}
                  error={validatePhones.invalid}
                  value={state.phone}
                  onBlur={onBlurPhone}
                />
                {validatePhones.invalid ? (
                  <FormHelperText sx={{ color: "red" }}>
                    {validatePhones.errors}
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </FormControl>

              <FormControl required>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>
                  Gói dịch vụ
                </FormLabel>
                <Select
                  name="package_id"
                  sx={{
                    border: "1px solid",
                    backgroundColor: "#DDF1FF",
                  }}
                  color="primary"
                  value={state.package_id}
                  onChange={onChangePackage}
                >
                  {list_package.map((x) => {
                    return (
                      <Option key={`${x._id}`} value={`${x._id}`}>
                        {x.package_name}
                      </Option>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>
                  Ngày sinh
                </FormLabel>
                {/* <Input
                name="birth_day"
                type="date"
                sx={{
                  pt: "8px",
                  border: "1px solid",
                  backgroundColor: "#DDF1FF",
                }}
                color="primary"
                onChange={handleChange}
                value={state.birth_day}
              /> */}
                <DatePicker
                  onChange={handleDateChange}
                  selected={state.birth_day}
                  customInput={
                    <Input
                      name="birth_day"
                      sx={{
                        py: "auto",
                        border: "1px solid",
                        backgroundColor: "#DDF1FF",
                      }}
                      color="primary"
                      endDecorator={<CalendarTodaySharpIcon fontSize="small" />}
                    />
                  }
                  locale="vi"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                />
              </FormControl>
              <FormControl>
                <FormLabel sx={{ color: "rgb(0, 49, 104)" }}>
                  Giới tính
                </FormLabel>
                <Select
                  name="gender"
                  value={state.gender.toString()}
                  sx={{
                    border: "1px solid",
                    backgroundColor: "#DDF1FF",
                  }}
                  color="primary"
                  onChange={(e: any, newValue) => {
                    console.log(newValue);
                    setState({ ...state, gender: Number(newValue) });
                  }}
                >
                  <Option value="1"> Nam</Option>
                  <Option value="2"> Nữ </Option>
                </Select>
              </FormControl>

              <Button
                sx={{
                  alignSelf: "center",
                  backgroundColor: "#003168",
                  width:"150px"
                }}
                type="submit"
              >
                Đăng Ký Ngay
              </Button>
              {/* <Typography level="body1" component="div" style={{paddingTop:"5px"}}><b>(*)Thông tin liên hệ:</b></Typography>
              <Typography level="body2" component="div" style={{marginTop:"10px"}} startDecorator={<Avatar variant="solid" size="sm"><PlaceIcon/></Avatar>}> P1210, Tòa nhà TRINITY TOWER, 145 Đ. Hồ Mễ Trì, Nhân Chính, Thanh Xuân, Hà Nội</Typography>
              <div style={{display:"flex", justifyContent:"flex-start", gap:5}}>
                <Typography level="body2" component="div" startDecorator={
                <a href="mailto:hotro@giaphadaiviet.com">
                <img src='https://giapha.akb.com.vn/images/email.png' width='32px' />
                </a>}> </Typography>
                <Typography level="body2" component="div" startDecorator={<a href="https://giaphaso.com"><img src='https://giapha.akb.com.vn/images/web.png' width='32px' /></a>}> </Typography>
                <Typography level="body2" component="div" startDecorator={<a href="tel:(+8424)979.33.88.11"><img src='https://giapha.akb.com.vn/images/phone.png' width='32px' /></a>}> </Typography>
              </div> */}
              <Divider sx={{ "--Divider-childPosition": `50%` }}>
                <b>Hoặc đăng nhập tại</b>
              </Divider>
              <Link
                variant="outlined"
                startDecorator={<LanguageIcon fontSize="small" />}
                underline="hover"
                href="https://dungthu.giaphaso.com/"
                fontSize="sm"
                sx={{
                  alignSelf: "center",
                  textAlign: "center",
                  margin: "auto",
                  width: "220px",
                  border: "1px solid rgba(25, 118, 210, 0.5)",
                  height: "35px",
                  borderRadius: "8px",
                  ":hover": {
                    bgcolor: "rgba(25, 118, 210, 0.04)",
                    border: "1px solid rgba(25, 118, 210)",
                  },
                }}
              >
                https://dungthu.giaphaso.com
              </Link>
              <CommonPopupRender {...initPopup} />
            </Stack>
          </form>
        </Card>
      </div>
      {/* <div style={{
        position :"fixed",
        zIndex:"999",
        right:0,
        bottom:0,
        top:0,
        left:0
    }} role="presentation">
    <CircularProgress thickness={4} />;
    </div> */}
      <Backdrop
        sx={{
          background: "rgba(245, 245, 245, 0.6)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(5px)",
        }}
        open={loading}
      >
        <CircularProgress thickness={4} size="lg" variant="plain" />
      </Backdrop>
    </>
  );
}
