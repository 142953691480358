import { ModalDialog, ModalClose, Modal, Button, Typography, Divider, Box } from "@mui/joy";
import * as React from 'react';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAltOutlined';
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";

type OptionalPopup = {
    openFlag: boolean;
    type: string;
    content: any;
    onPass?: any;
    onCancel?: any;
    setOpenFlag: (f: boolean)=> void;
}

export default function CommonPopupRender ({ openFlag, type , content, onPass, onCancel, setOpenFlag}: OptionalPopup) {
    const handleClose = () => {
        setOpenFlag(false);
        if (onCancel) onCancel();
    }

    const handleOk = () => {
        setOpenFlag(false);
        if (onPass) onPass();
    }
    
    return (
        <Modal open={openFlag} onClose={handleClose}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            size="md"
            variant="outlined"
          >
            <ModalClose />
            
            <Typography
                component="h2"
                id="basic-modal-dialog-title"
                sx={{ marginBottom: "1.5vh" }}
            >
                Thông báo
            </Typography>
            <Divider />
            {type == "success" ? (
                <>
                <Typography startDecorator = {<CheckCircleIcon color="success" sx={{ fontSize: 50 }} />} level="body-lg" id="basic-modal-dialog-description" color="success">  {content} </Typography>
                <Button color="success" onClick={handleOk}> Thoát </Button> 
                </>
            )
            : (type == "fail" ? 
                <>
                <Typography startDecorator = {<DoDisturbAltIcon color="error" sx={{ fontSize: 50 }} />} level="body-lg" id="basic-modal-dialog-description" color="danger" > {content} </Typography>
                <Button color="danger" onClick={handleOk}> Thoát </Button> 
                </>
            : (type == "confirmed" ? 
                <div style={{ 
                    // width: "20vw" 
                    }}>
                    <Typography
                    id="basic-modal-dialog-description"
                    textColor="text.tertiary"
                    level="body-lg"
                    component={'span'}
                    // sx={{ marginBottom: "1.5vh" }}
                    >
                    {content}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                        <Button
                            variant="plain" color="neutral"
                            onClick={handleClose}
                        >
                            Thoát
                        </Button>
                        <Button
                            variant="solid" 
                            onClick={handleOk}
                        >
                        Xác nhận
                        </Button>
                    </Box>
                </div>
            :<></>))}
            
          </ModalDialog>
        </Modal>
    );

    
  };