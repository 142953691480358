import { Box, Button, Card, CardContent, Chip, ChipDelete, CircularProgress, FormControl, FormHelperText, FormLabel, Input, Radio, RadioGroup, Select, Table, Typography } from "@mui/joy";
import * as React from "react";
import BackgroundRongPhuong from "../../assets/images/RongPhuong.svg";
import BackgroundIMG from "../../assets/images/bggiapha.jpg";
import configs from "../../config";
import { useEffect, useState } from "react";
import CommonPopupRender from "../../common/popuprender";
import { convertViToEn, isEmpty, validateHost} from "../../common/expression";
import { useNavigate, useParams } from "react-router-dom";
import app from "../../common/sendrequest";
import "./PublicAddDomain.css"; 
import { Backdrop } from "@mui/material";
import axios, { AxiosError } from "axios";
type ServerError = { message: string };
const initialValidate = { invalid: false, errors: "" };

export default function QuickAddDomain() {
    const [initPopup, setInitPopup] = React.useState({
        openFlag: false,
        type: "",
        content: "",
        onPass: () => {},
        onCancel: () => {},
        setOpenFlag: (flag: boolean) =>
          setInitPopup({ ...initPopup, openFlag: flag }),
      });
    const [customer, SetCustomer] = useState({
      full_name: '',
      email:'',
      phone:'',
      family: {
        family_alias_name:'',
        family_name:'',
        family_anniversary:'',
        family_alias_number:0,
        family_hometown:'',
      }
    });
    const [sub_domain, SetSubdomain] = useState('');
    const [infor_flag, setInforFlag] = useState(true);
    const [success_flag, setSuccessFlag] = useState(false);
    const [error_flag, setErrorFlag] = useState(false);
    const [message, setMessage] = useState(<></>);
    const [loading, setLoading] = useState(false);
    const [validateSubDomain , setvalidateSubDomain] = useState(initialValidate);
    const [suggest_list, setSuggestList] = useState<string[]>([]);
    let { id } = useParams();
    let navigate = useNavigate();


    useEffect(()=>{
      getData();
    },[]);


    const handleError = (err: any) => {
      setLoading(false);
      if (axios.isAxiosError(err)) {
        const serverError = err as AxiosError<ServerError>;
        if (serverError && serverError.response) {
          setInitPopup({
            ...initPopup,
            openFlag: true,
            type: "fail",
            content: serverError.response.data.message,
          });
        }
      }
    };

    const getData = ()=>{
      app
      .post(`${configs.customer}infor-detail/${id}`)
      .then((res: any) => {
        if(res.data)
        {
          const data = res.data;
          const init_lucky_number = [6868,8888,9999,369,151618,136, 9279, 569, 6666, 456];
          let lucky_number = init_lucky_number;
          let hometown = (
            data.family!.family_hometown ? data.family!.family_hometown : ""
          )!
            .toLowerCase()
            .split(/[,-]/gi)
            .map((x: string) => convertViToEn(x));

          if (hometown[0] != hometown[hometown.length - 1]) {
            hometown = [
              hometown[0],
              hometown[hometown.length - 1],
              hometown[0]
                .split(" ")
                .map((word: string) => word.charAt(0))
                .join(""),
              hometown[hometown.length - 1]
                .split(" ")
                .map((word: string) => word.charAt(0))
                .join(""),
            ];
          } else if(hometown[0].length < 20 && hometown[0].length > 0){
            hometown = [
              hometown[0],
              hometown[0]
                .split(" ")
                .map((word: string) => word.charAt(0))
                .join(""),
            ];
          }

          hometown = hometown.map((x: string) => x.replace(/\s/g, ""));
          console.log(hometown);
          const familyname = convertViToEn(
            (data.family!.family_name ? data.family!.family_name : "")!
              .toLowerCase()
              .replace(/\s/g, "")
          );
          
          let familyann = convertViToEn(
            (data.family!.family_anniversary
              ? data.family!.family_anniversary
              : "")!.replace(/^a-zA-Z0-9 ]/g, "")
          );
          
          const arr: string[] = [familyname];
          let i=0;

          do{
            //hometown
            if(hometown.length > 0 && hometown[0].length > 0){
              for(let j = 0 ; j< hometown.length; j++)
              {
                if(hometown[j].trim().length > 0 && hometown[j].trim().length < 20)
                {
                  arr.push(arr[0].concat(hometown[j]));
                  arr.push(hometown[j].concat(arr[0]));
                  i+=2;
                }
              }
              hometown =[""];
            }
            
            if(i < 10 && familyann.trim().length > 0) {
              arr.push(arr[0].concat(familyann.trim()));
              arr.push(familyann.trim().concat(arr[0]));
              i+=2;
              familyann = '';
            }
            if(i < 10 && hometown[0].length == 0 && familyann.trim().length == 0) {
              const index = Math.floor(Math.random() * lucky_number.length);
              arr.push(`${arr[0]}${lucky_number[index]}`);
              lucky_number.splice(index,1);
              i++;
            }
          }while(i < 9);
          setSuggestList(arr);
          SetCustomer(data);
        }else{
          
        }
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isAxiosError(err)) {
          const serverError = err as AxiosError<ServerError>;
          if (serverError && serverError.response) {
            if( serverError.response.status == 409 ){
              setErrorFlag(true);
              setInforFlag(false);
              setMessage(<>{serverError.response.data.message}</>);
            }else{
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "fail",
                content: serverError.response.data.message,
                onCancel:()=>{navigate("/");},
                onPass: ()=>{navigate("/");}
              });
            }
          }
        }
      });
      
    }

    const onInputSubdomain = (e :any) => {
      (e.target as HTMLInputElement).setCustomValidity("");
      setvalidateSubDomain({ invalid: false, errors: "" });
    }

    const onBlurNameHandler = (e: any) => {
      if (isEmpty(e.target.value)) {
        setvalidateSubDomain({
          invalid: true,
          errors: "Tên miền không được để trống",
        });
      } else if (!validateHost(e.target.value)) {
        setvalidateSubDomain({
          invalid: true,
          errors: "Tên miền không đúng định dạng",
        });
      } else {
        setvalidateSubDomain({ invalid: false, errors: "" });
      }
    };

    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
      if(sub_domain.length == 0 || validateSubDomain.invalid){
        return;
      }
      event.preventDefault();
      setLoading(true);

      await app
      .post(configs.customer + "infor-update", {
        domain: [`https://${sub_domain}.giaphaso.com`],
        token: id,
        list_add_domain: [sub_domain]
      })
      .then(async (data: any) => {
        setLoading(false);
        setInforFlag(false);
        setSuccessFlag(true);
        setMessage(<>Yêu cầu đăng ký tên miền của quý khách đang được xử lý.<br/>(Thời gian xử lý trong 1 - 2 ngày)</>);
      })
      .catch((err) => {
        console.log(err);
        handleError(err);
      });
    }
    return (
      <Box sx={{ width: "100%" }}>
        <div
          className="MainBody"
          style={{
            backgroundColor: "#fff8a9",
            backgroundPosition: "center",
            backgroundImage: `url(${BackgroundRongPhuong})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100vh",
            width: "100%",
            alignContent: "center",
            color: "#003168",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "9px",
          }}
        >
          <Typography
            level="h1"
            component="h1"
            className="cssTitle"
            sx={{
              alignSelf: "center",
              textAlign: "center",
              color: "#003168",
              // top: 0,
              margin: "3vh",
              // position: "absolute",
            }}
          >
            {customer!.family!.family_name
              ? `Dòng họ ${customer!.family!.family_name}`
              : ""}
          </Typography>
          <Card
            sx={{
              border: "0.1px solid",
              width: "fit-content",
              minWidth: "450px",
              maxWidth:"45%",
              padding: "5px",
              backgroundImage: `url(${BackgroundIMG})`,
            }}
          >
            <CardContent
              sx={{
                opacity: Number(infor_flag),
                display: infor_flag? undefined : 'none',
              }}
            >
              <Typography
                level="h2"
                sx={{
                  // fontWeight:'bold',
                  alignSelf: "center",
                  textAlign: "center",
                  color: "#003168",
                  width: "100%",
                  padding: "11px 0px",
                }}
              >
                ĐĂNG KÝ TÊN MIỀN DỊCH VỤ GIA PHẢ SỐ ĐẠI VIỆT
              </Typography>
              <Table
                borderAxis="none"
                color="neutral"
                size="lg"
                sx={{
                  margin: "auto",
                  minWidth: "50%",
                  "--TableCell-height": "38px",
                  "--TableCell-paddingY": "0px",
                  "& th": {
                    backgroundColor: "unset",
                  },
                }}
              >
                <tbody>
                  <tr>
                    <th>Dòng họ: </th>
                    <td>{customer!.family!.family_name}</td>
                  </tr>
                  {customer!.family!.family_hometown && (
                    <tr>
                      <th>Nguyên quán: </th>
                      <td>{customer!.family!.family_hometown}</td>
                    </tr>
                  )}
                  {customer!.family!.family_anniversary && (
                    <tr>
                      <th>Ngày giỗ: </th>
                      <td>{customer!.family!.family_anniversary}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Người đăng ký: </th>
                    <td>{customer!.full_name}</td>
                  </tr>
                  <tr>
                    <th>Số điện thoại: </th>
                    <td>{customer!.phone}</td>
                  </tr>
                </tbody>
              </Table>
              <Typography
                sx={{
                  alignSelf: "center",
                  textAlign: "center",
                  color: "#003168",
                  padding: "0px 11px",
                }}
              >
                Quý khách có thể tạo tên miền riêng cho dòng họ của mình theo
                tên miền của Gia Phả Số Đại Việt
              </Typography>
              <form onSubmit={onSubmitHandler}>
                <FormControl
                  sx={{
                    display: "grid",
                    width: "95%",
                    margin: "auto",
                    my: "10px",
                  }}
                >
                  <FormControl sx={{ width: "100%" }} required>
                    <FormLabel>Tên miền:</FormLabel>
                    <Input
                      name="domain"
                      className="InputForm"
                      placeholder={"domain"}
                      autoFocus
                      type="text"
                      value={sub_domain}
                      sx={{
                        width: "100%",
                        "--Input-decoratorChildHeight": "40px",
                      }}
                      endDecorator={".giaphaso.com"}
                      onInput={onInputSubdomain}
                      onFocus={() =>
                        setvalidateSubDomain({ invalid: false, errors: "" })
                      }
                      onBlur={onBlurNameHandler}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        SetSubdomain(value);
                      }}
                    />
                    {validateSubDomain.invalid && (
                      <FormHelperText sx={{ color: "red" }}>
                        {validateSubDomain.errors}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormHelperText
                    sx={{
                      width: "100%",
                      my: 1,
                    }}
                  >
                    Gợi ý:
                  </FormHelperText>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.3,
                    }}
                  >
                    {suggest_list.length > 0 &&
                      suggest_list.map((x, i) => (
                        <Chip
                          variant="outlined"
                          color="success"
                          size="lg"
                          key={i}
                          onClick={() => {
                            SetSubdomain(x);
                            setvalidateSubDomain({
                              invalid: false,
                              errors: "",
                            });
                          }}
                        >
                          {x}
                        </Chip>
                      ))}
                  </Box>
                </FormControl>
                <Box
                  sx={{
                    width: "95%",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="submit"
                    sx={{ width: "fit-content" }}
                  >
                    Đăng ký tên miền
                  </Button>
                </Box>
              </form>
            </CardContent>
            <CardContent
              sx={{
                opacity: Number(success_flag),
                display: success_flag? undefined : 'none',
                textAlign: 'center',
                margin: '10px',
              }}
            >
              <div className="sweet-alert">
                <div className="icon success animate">
                  <span className="line tip animateSuccessTip"></span>
                  <span className="line long animateSuccessLong"></span>
                  <span className="placeholder"></span>
                  <span className="fix"></span>
                </div>
              </div>
              <Typography level="h1" color="success">Xác nhận đăng ký tên miền thành công !</Typography>
              <Typography level="h4" sx={{marginBottom: 2, fontWeight: 'var(--joy-fontWeight-lg, 400)'}}>{message}</Typography>
            </CardContent>
            <CardContent
              sx={{
                opacity: Number(error_flag),
                display: error_flag? undefined : 'none',
                textAlign: 'center',
              }}
            >
              <div className="sweet-alert">
                <div className="icon error animateErrorIcon">
                  <span className="x-mark animateXMark">
                    <span className="line left"></span>
                    <span className="line right"></span>
                  </span>
                </div>
              </div>
              <Typography level="h1" color="danger">Có lỗi xảy ra!</Typography>
              <Typography level="h4" sx={{marginBottom: 5, fontWeight: 'var(--joy-fontWeight-lg, 400)'}}>{message}</Typography>
            </CardContent>
          </Card>
          <CommonPopupRender {...initPopup} />
          <Backdrop
            sx={{
              background: "rgba(245, 245, 245, 0.6)",
              zIndex: 10000,
              backdropFilter: "blur(5px)",
            }}
            open={loading}
            transitionDuration={{
              appear: 1,
              exit: 1,
            }}
          >
            <CircularProgress thickness={4} size="lg" variant="plain" />
          </Backdrop>
        </div>
      </Box>
    );
}